import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { X } from "lucide-react"
import { Button } from "@/components/ui/button"
import { BotIcon, BotIconSmall } from "@/components/icons"
import "../styles/fonts.css"

// Import font files directly
import AeonikRegular from '../assets/fonts/Aeonik-Regular.otf'
import AeonikMedium from '../assets/fonts/Aeonik-Medium.otf'

// Add font loading check
const loadFonts = async () => {
  try {
    const regular = new FontFace('Aeonik', `url(${AeonikRegular})`, {
      weight: 'normal',
      style: 'normal',
    });
    const medium = new FontFace('Aeonik', `url(${AeonikMedium})`, {
      weight: '500',
      style: 'normal',
    });

    await Promise.all([regular.load(), medium.load()]);
    document.fonts.add(regular);
    document.fonts.add(medium);
    console.log('Aeonik fonts loaded successfully');
  } catch (error) {
    console.error('Failed to load Aeonik fonts:', error);
  }
};

export function MyStylus() {
  const { t, i18n } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [showFirstPopup, setShowFirstPopup] = useState(false)
  const [showSecondPopup, setShowSecondPopup] = useState(false)
  const [showThirdPopup, setShowThirdPopup] = useState(false)
  const [showToolButtons, setShowToolButtons] = useState(false)
  const [showOpenWidgetTools, setShowOpenWidgetTools] = useState(false)
  const [showOpenWidgetButtons, setShowOpenWidgetButtons] = useState(false)

  // Add function to get rid based on hostname
  const getRidValue = () => {
    const hostname = window.location.hostname;
    if (hostname.includes('essayforum.com')) {
      return '061a7e00aec7ead1';
    } else if (hostname.includes('gerador-de-redacao.com')) {
      return '5cdabd9a0f955ce8';
    } else if (hostname.includes('infonormas.com.br')) {
      return '479f8431c889529d';
    } else if (hostname.includes('elimucentre.com')) {
      return 'c739dbacfab9d264';
    } else if (hostname.includes('euquerosabertudo.com')) {
      return 'caa77cd6aa4900dc';
    } else if (hostname.includes('digitalkw.com')) {
      return '7791f56b51591643';
    } else if (hostname.includes('expertprogrammanagement.com')) {
      return '4f6220db8bd3800b';
    } else if (hostname.includes('businesscasestudies.co.uk')) {
      return 'e9786bf8e08f604d';
    } else if (hostname.includes('janniaiwriter.com') || 
               hostname.includes('jasperaiwrite.com') || 
               hostname.includes('gramorlyai.com')) {
      return 'cf92ceac08d9ffb6';
    } else if (hostname.includes('myspeechclass.com')) {
      return '506ea3a3d14b1a9b';
    }
    return '061a7e00aec7ead1'; // default fallback
  };

  useEffect(() => {
    loadFonts();
  }, []);

  const toggleChat = () => setIsOpen(!isOpen)
  const closeAllMessages = () => {
    setShowFirstPopup(false)
    setShowSecondPopup(false)
    setShowThirdPopup(false)
    setShowToolButtons(false)
  }

  const changeLanguage = (lng: string) => {
    console.log('Changing language to:', lng);
    i18n.changeLanguage(lng).then(() => {
      console.log('Language changed successfully');
      console.log('Current language:', i18n.language);
    }).catch((err) => {
      console.error('Error changing language:', err);
    });
  }

  // Close language menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.language-menu') && !target.closest('.more-button')) {
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  useEffect(() => {
    // Show first popup and second popup together after 2 seconds
    const firstAndSecondTimer = setTimeout(() => {
      setShowFirstPopup(true)
      setShowSecondPopup(true)
    }, 2000)

    // Show third popup (Smart Tools message) after 5 seconds (3 seconds after first message)
    const thirdTimer = setTimeout(() => {
      setShowThirdPopup(true)
    }, 5000)

    // Show tool buttons after 7 seconds (2 seconds after Smart Tools message)
    const toolsTimer = setTimeout(() => {
      setShowToolButtons(true)
    }, 7000)

    if (isOpen) {
      const toolsTimer = setTimeout(() => {
        setShowOpenWidgetTools(true)
      }, 2000)

      const buttonsTimer = setTimeout(() => {
        setShowOpenWidgetButtons(true)
      }, 4000)

      return () => {
        clearTimeout(toolsTimer)
        clearTimeout(buttonsTimer)
      }
    } else {
      setShowOpenWidgetTools(false)
      setShowOpenWidgetButtons(false)
    }

    return () => {
      clearTimeout(firstAndSecondTimer)
      clearTimeout(thirdTimer)
      clearTimeout(toolsTimer)
    }
  }, [isOpen])

  const getLanguageLabel = (code: string) => {
    return code.toUpperCase();
  };

  return (
    <div className="fixed bottom-6 right-6 z-[9999] my-stylus-container">
      {isOpen ? (
        <div className="fixed inset-0 sm:relative sm:inset-auto bg-white sm:rounded-3xl shadow-lg w-full sm:w-[400px] h-screen sm:h-[500px] flex flex-col overflow-hidden">
          {/* Header */}
          <div className="widget-padding flex items-center justify-between border-b shrink-0">
            <div className="flex items-center">
              <div className="flex items-center space-x-2">
                <BotIconSmall />
                <div>
                  <h3 className="font-[500] text-gray-900">{t('mystylus.title')}</h3>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="relative">
                <div className="rounded-xl shadow-sm">
                  <select 
                    value={i18n.language}
                    onChange={(e) => changeLanguage(e.target.value)}
                    className="block w-[60px] py-1 pl-2 text-sm border-0 focus:outline-none focus:ring-0 rounded-xl font-['Aeonik'] bg-[#F2F2ED]"
                  >
                    {['en', 'fr', 'es', 'pt', 'de', 'zh'].map((lang) => (
                      <option key={lang} value={lang}>
                        {getLanguageLabel(lang)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <Button variant="ghost" size="icon" className="h-8 w-8" onClick={() => {
                closeAllMessages();
                toggleChat();
              }}>
                <X className="h-5 w-5 text-gray-600" />
              </Button>
            </div>
          </div>

          {/* Message Content */}
          <div className="flex-1 overflow-y-auto p-3 space-y-3 w-full flex flex-col">
            {/* Greeting message - always first */}
            <div className="inline-block max-w-[35%] animate-in fade-in slide-in-from-bottom-3 duration-300 ease-out">
              <div className="bg-[#F2F2ED] rounded-2xl [border-top-left-radius:0] widget-padding transform-gpu transition-all">
                <p className="text-gray-900 text-[0.938rem] whitespace-nowrap">
                  {t('mystylus.popup.greeting')}
                </p>
              </div>
            </div>
            {/* Platform description message */}
            <div className="inline-block max-w-[75%] animate-in fade-in slide-in-from-bottom-3 duration-300 ease-out delay-100">
              <div className="bg-[#F2F2ED] rounded-2xl [border-top-left-radius:0] widget-padding transform-gpu transition-all">
                <div className="space-y-4">
                  {t('mystylus.popup.text.0')}
                  <p className="text-gray-900 text-[0.938rem]">{t('mystylus.popup.text.1')}</p>
                </div>
              </div>
            </div>
            {/* Generate button */}
            <div className="inline-block animate-in fade-in slide-in-from-bottom-3 duration-300 ease-out delay-200">
              <Button
                asChild
                className="h-auto bg-[#232323] hover:bg-[#2c2c2c] hover:text-white text-white font-[500] text-base rounded-[8px] font-['Aeonik'] px-4 py-2 transform-gpu transition-all"
                onClick={() => {
                  window.ym?.(100299336, 'reachGoal', 'academic_work');
                }}
              >
                <a 
                  href={`https://eduforms.org/?rid=${getRidValue()}&lang=${i18n.language}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('mystylus.button.generate')}
                </a>
              </Button>
            </div>
            {/* Smart Tools message */}
            {showOpenWidgetTools && (
              <div className="inline-block max-w-[75%] animate-in fade-in slide-in-from-bottom-3 duration-300 ease-out delay-300">
                <div className="bg-[#F2F2ED] rounded-2xl [border-top-left-radius:0] widget-padding transform-gpu transition-all">
                  <p className="text-gray-900 text-[0.938rem]">
                    {t('mystylus.popup.smart_tools')}
                  </p>
                </div>
              </div>
            )}
            {/* Tool buttons */}
            {showOpenWidgetButtons && (
              <div className="space-y-2 animate-in fade-in slide-in-from-bottom-3 duration-300 ease-out delay-400">
                <div className="flex flex-wrap gap-2">
                  <Button
                    asChild
                    className="h-auto bg-[#232323] hover:bg-[#2c2c2c] hover:text-white text-white font-[500] text-base rounded-[8px] font-['Aeonik'] px-4 py-2 shadow-lg"
                    onClick={() => {
                      window.ym?.(100299336, 'reachGoal', 'paragraph_generator');
                    }}
                  >
                    <a href={`https://eduforms.org/?rid=${getRidValue()}&ulp=https%3A%2F%2Fmystylus.ai%2Ftools%2F%3Ftool%3Dai-paragraph-generator&lang=${i18n.language}`} target="_blank" rel="noopener noreferrer">
                      {t('mystylus.button.tools.paragraph')}
                    </a>
                  </Button>
                  <Button
                    asChild
                    className="h-auto bg-[#232323] hover:bg-[#2c2c2c] hover:text-white text-white font-[500] text-base rounded-[8px] font-['Aeonik'] px-4 py-2 shadow-lg"
                    onClick={() => {
                      window.ym?.(100299336, 'reachGoal', 'paraphraser');
                    }}
                  >
                    <a href={`https://eduforms.org/?rid=${getRidValue()}&ulp=https%3A%2F%2Fmystylus.ai%2Ftools%2F%3Ftool%3Drewriter-paraphraser&lang=${i18n.language}`} target="_blank" rel="noopener noreferrer">
                      {t('mystylus.button.tools.paraphrase')}
                    </a>
                  </Button>
                </div>
                <Button
                  asChild
                  className="h-auto bg-[#232323] hover:bg-[#2c2c2c] hover:text-white text-white font-[500] text-base rounded-[8px] font-['Aeonik'] px-4 py-2 shadow-lg"
                  onClick={() => {
                    window.ym?.(100299336, 'reachGoal', 'summarizer');
                  }}
                >
                  <a href={`https://eduforms.org/?rid=${getRidValue()}&ulp=https%3A%2F%2Fmystylus.ai%2Ftools%3Ftool%3Dadvanced-summarizer&lang=${i18n.language}`} target="_blank" rel="noopener noreferrer">
                    {t('mystylus.button.tools.summarize')}
                  </a>
                </Button>
              </div>
            )}
          </div>
          {/* Footer */}
          <div className="p-2 text-center border-t text-sm text-gray-400 font-['Aeonik']">
            by Edugram
          </div>
        </div>
      ) : (
        <div className="relative">
          <div className="absolute bottom-[68px] right-0 space-y-3">
            <div className="flex justify-end mb-[-25px] mr-[18px] relative z-10">
              <div className="relative">
                <div className="rounded-xl shadow-sm">
                  <select 
                    value={i18n.language}
                    onChange={(e) => changeLanguage(e.target.value)}
                    className="block w-[60px] py-1 pl-2 text-sm border-0 focus:outline-none focus:ring-0 rounded-xl font-['Aeonik'] bg-[#F2F2ED]"
                  >
                    {['en', 'fr', 'es', 'pt', 'de', 'zh'].map((lang) => (
                      <option key={lang} value={lang}>
                        {getLanguageLabel(lang)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {showFirstPopup && (
              <div className="animate-in fade-in slide-in-from-bottom-3 duration-300 ease-out scale-95 initial-hidden animate-show">
                <div className={`relative bg-white rounded-2xl shadow-lg widget-padding w-80 transform-gpu transition-all font-['Aeonik'] group`}>
                  <button 
                    onClick={closeAllMessages}
                    className="absolute -top-2 -right-2 bg-[#6B7280] hover:bg-[#4B5563] text-white w-6 h-6 rounded-full flex items-center justify-center initial-hidden group-hover:opacity-100 transition-opacity"
                  >
                    <X className="h-3 w-3" />
                  </button>
                  <div className="space-y-3">
                    <div className="flex items-center space-x-2">
                      <BotIconSmall className="w-7 h-7" />
                      <span className="font-[500] text-gray-900">{t('mystylus.title')}</span>
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm font-[400] text-gray-900 text-[0.938rem]">
                        {t('mystylus.popup.greeting')}
                      </p>
                      <div className="space-y-4">
                        <p className="text-sm font-[400] text-gray-900 text-[0.938rem]">{t('mystylus.popup.text.0')}</p>
                        <p className="text-sm font-[400] text-gray-900 text-[0.938rem]">{t('mystylus.popup.text.1')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showSecondPopup && (
              <div className="animate-in fade-in slide-in-from-bottom-3 duration-300 ease-out scale-95 initial-hidden animate-show delay-100">
                <Button
                  asChild
                  className="w-80 h-auto bg-[#232323] hover:bg-[#2c2c2c] hover:text-white text-white font-[500] text-base rounded-[8px] font-['Aeonik'] px-4 py-2 transform-gpu transition-all"
                  onClick={() => {
                    window.ym?.(100299336, 'reachGoal', 'academic_work');
                  }}
                >
                  <a 
                    href={`https://eduforms.org/?rid=${getRidValue()}&lang=${i18n.language}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('mystylus.button.generate')}
                  </a>
                </Button>
              </div>
            )}
            {showThirdPopup && (
              <div className="animate-in fade-in slide-in-from-bottom-3 duration-300 ease-out scale-95 initial-hidden animate-show delay-200">
                <div className={`relative bg-white rounded-2xl shadow-lg widget-padding w-80 transform-gpu transition-all font-['Aeonik'] mt-6`}>
                  <div className="space-y-3">
                    <div className="flex items-center space-x-2">
                      <BotIconSmall className="w-7 h-7" />
                      <span className="font-[500] text-gray-900">{t('mystylus.title')}</span>
                    </div>
                    <p className="text-sm font-[400] text-gray-900 text-[0.938rem]">
                      {t('mystylus.popup.smart_tools')}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {showToolButtons && (
              <div className="space-y-2 animate-in fade-in slide-in-from-bottom-3 duration-300 ease-out scale-95 initial-hidden animate-show delay-300">
                <div className="flex flex-wrap gap-2">
                  <Button
                    asChild
                    className="h-auto bg-[#232323] hover:bg-[#2c2c2c] hover:text-white text-white font-[500] text-base rounded-[8px] font-['Aeonik'] px-4 py-2 shadow-lg"
                    onClick={() => {
                      window.ym?.(100299336, 'reachGoal', 'paragraph_generator');
                    }}
                  >
                    <a href={`https://eduforms.org/?rid=${getRidValue()}&ulp=https%3A%2F%2Fmystylus.ai%2Ftools%2F%3Ftool%3Dai-paragraph-generator&lang=${i18n.language}`} target="_blank" rel="noopener noreferrer">
                      {t('mystylus.button.tools.paragraph')}
                    </a>
                  </Button>
                  <Button
                    asChild
                    className="h-auto bg-[#232323] hover:bg-[#2c2c2c] hover:text-white text-white font-[500] text-base rounded-[8px] font-['Aeonik'] px-4 py-2 shadow-lg"
                    onClick={() => {
                      window.ym?.(100299336, 'reachGoal', 'paraphraser');
                    }}
                  >
                    <a href={`https://eduforms.org/?rid=${getRidValue()}&ulp=https%3A%2F%2Fmystylus.ai%2Ftools%2F%3Ftool%3Drewriter-paraphraser&lang=${i18n.language}`} target="_blank" rel="noopener noreferrer">
                      {t('mystylus.button.tools.paraphrase')}
                    </a>
                  </Button>
                </div>
                <Button
                  asChild
                  className="h-auto bg-[#232323] hover:bg-[#2c2c2c] hover:text-white text-white font-[500] text-base rounded-[8px] font-['Aeonik'] px-4 py-2 shadow-lg"
                  onClick={() => {
                    window.ym?.(100299336, 'reachGoal', 'summarizer');
                  }}
                >
                  <a href={`https://eduforms.org/?rid=${getRidValue()}&ulp=https%3A%2F%2Fmystylus.ai%2Ftools%3Ftool%3Dadvanced-summarizer&lang=${i18n.language}`} target="_blank" rel="noopener noreferrer">
                    {t('mystylus.button.tools.summarize')}
                  </a>
                </Button>
              </div>
            )}
          </div>
          <Button
            onClick={toggleChat}
            className="rounded-full w-12 h-12 bg-[#232323] hover:bg-[#2c2c2c] p-0 flex items-center justify-center [&_svg]:!w-auto [&_svg]:!h-auto"
          >
            <BotIcon />
          </Button>
        </div>
      )}
    </div>
  )
}

