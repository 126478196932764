import { MyStylus } from './components/my-stylus'
import "./styles/fonts.css";
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n/config'
import { YandexMetrika } from './components/YandexMetrika'

function App() {
 
  return (
    <I18nextProvider i18n={i18n}>
      <div className="__MyStylus__">
        <YandexMetrika />
        <MyStylus />
      </div>
    </I18nextProvider>
  )
}

export default App
