import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './locales/en.json';
import frTranslations from './locales/fr.json';
import esTranslations from './locales/es.json';
import ptTranslations from './locales/pt.json';
import deTranslations from './locales/de.json';
import zhTranslations from './locales/zh.json';

const resources = {
  en: {
    translation: enTranslations,
  },
  fr: {
    translation: frTranslations,
  },
  es: {
    translation: esTranslations,
  },
  pt: {
    translation: ptTranslations,
  },
  de: {
    translation: deTranslations,
  },
  zh: {
    translation: zhTranslations,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr', 'es', 'pt', 'de', 'zh'],
    debug: process.env.NODE_ENV === 'development',
    detection: {
      order: ['localStorage', 'cookie', 'navigator', 'htmlTag'],
      caches: ['localStorage', 'cookie'],
      cookieMinutes: 43200, // 30 days
      cookieDomain: window.location.hostname
    },
    interpolation: {
      escapeValue: false,
    },
    returnObjects: true,
  });

i18n.on('initialized', () => {
  console.log('i18n initialized');
  console.log('Current language:', i18n.language);
  console.log('Available languages:', i18n.languages);
  console.log('Loaded resources:', i18n.store.data);
});

i18n.on('languageChanged', (lng) => {
  console.log('Language changed to:', lng);
});

export default i18n; 