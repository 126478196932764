import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import "./styles/fonts.css";
import App from './App'

const widgetContainer = document.createElement("div");
widgetContainer.id = "msd-widget-stylus";

document.body.appendChild(widgetContainer);

createRoot(widgetContainer).render(
    <StrictMode>
      <App />
    </StrictMode>,
  );

