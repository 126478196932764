import { useEffect } from 'react';

declare global {
  interface Window {
    ym: (counterId: number, action: string, params?: any) => void;
  }
}

export function YandexMetrika() {
  useEffect(() => {
    // Inject Yandex Metrika script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      for(var j=0;j<document.scripts.length;j++){if(document.scripts[j].src===r)return;}
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r;
      a?a.parentNode.insertBefore(k,a):e.head.appendChild(k)})
      (window,document,"script","https://mc.yandex.ru/metrika/tag.js","ym");

      ym(100299336, "init", {
        defer: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
      });
    `;
    document.head.appendChild(script);

    // Add noscript fallback
    const noscript = document.createElement('noscript');
    const div = document.createElement('div');
    const img = document.createElement('img');
    img.src = 'https://mc.yandex.ru/watch/100299336';
    img.style.position = 'absolute';
    img.style.left = '-9999px';
    img.alt = '';
    div.appendChild(img);
    noscript.appendChild(div);
    document.body.appendChild(noscript);

    // Cleanup function
    return () => {
      const noscriptElements = document.getElementsByTagName('noscript');
      for (let i = 0; i < noscriptElements.length; i++) {
        if (noscriptElements[i].innerHTML.includes('mc.yandex.ru/watch/100299336')) {
          noscriptElements[i].remove();
          break;
        }
      }
    };
  }, []); // Empty dependency array means this runs once on mount

  return null;
} 