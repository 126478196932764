import { SVGProps } from "react"

export function BotIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.760742" width="40" height="40" rx="20" fill="#232323" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2872 21.9166L17.2872 17.5438L17.2872 17.5424C17.2872 17.1957 17.4263 16.8714 17.6682 16.5946C17.7806 16.466 17.9152 16.3476 18.0683 16.2419C18.5509 15.9091 19.2176 15.7033 19.9539 15.7033C21.4267 15.7033 22.6206 16.5267 22.6206 17.5424V17.8008H26.4444C26.2499 14.613 23.611 12.7611 19.7777 12.7611C16.111 12.7307 13.6388 14.7648 13.6388 17.8615C13.6388 20.2986 15.1204 21.3602 17.2872 21.9166ZM17.2872 23.3871H13.3333C13.4999 26.7267 16.1944 28.7608 20.1666 28.7608C23.9999 28.7608 26.6666 26.7874 26.6666 23.6907C26.6666 20.9015 24.9592 19.8072 22.6206 19.2916V24.1473C22.6206 24.5108 22.4863 24.8578 22.249 25.1247C22.188 25.1935 22.1201 25.2568 22.0459 25.3139C22.0459 25.3139 22.0459 25.3139 22.0458 25.3139L20.4022 26.5771L20.4022 26.5772C20.1378 26.7804 19.7699 26.7804 19.5056 26.5772L17.8619 25.3139C17.4996 25.0354 17.2872 24.6044 17.2872 24.1474V23.3871Z"
        fill="white"
      />
    </svg>
  )
}

export function BotIconSmall(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="28" height="28" rx="14" fill="#232323"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9231 11.5359V14.885C10.264 14.459 9.12969 13.6462 9.12969 11.7803C9.12969 9.40938 11.0225 7.85201 13.8298 7.87526C16.7647 7.87526 18.7851 9.29316 18.934 11.7338H16.0066V11.5359V11.5359C16.0066 10.7583 15.0925 10.1279 13.9649 10.1279C13.9065 10.1279 13.8487 10.1296 13.7915 10.1329C12.745 10.1935 11.9231 10.7985 11.9231 11.5359ZM13.996 18.569C13.8647 18.5762 13.7314 18.5377 13.6215 18.4532L12.3631 17.486C12.0856 17.2728 11.9231 16.9427 11.9231 16.5929V16.0108H8.89575C9.02336 18.5676 11.0863 20.125 14.1275 20.125C17.0624 20.125 19.1041 18.6141 19.1041 16.2432C19.1041 14.1079 17.7969 13.27 16.0066 12.8752L16.0066 16.5928C16.0066 16.9427 15.844 17.2727 15.5666 17.486L14.3081 18.4532C14.2156 18.5243 14.1065 18.5629 13.996 18.569Z"
        fill="white"
      />
    </svg>
  )
} 